import request from '@/utils/request'
export function getLastestNews (data) {
  return request({
    url: '/analysis/getNewest',
    method: 'POST',
    data
  })
}

// 站点排行
export function siteStatistics (data) {
  return request({
    url: '/analysis/siteStatistics',
    method: 'POST',
    data
  })
}
// 信息统计
export function getInfoStatistics (data) {
  return request({
    url: '/analysis/getInfoStatistics',
    method: 'POST',
    data
  })
}
// 舆情走势
export function getEmotion (data) {
  return request({
    url: '/analysis/getEmotion',
    method: 'POST',
    data
  })
}
// 舆情走势
export function tagWordCharts (data) {
  return request({
    url: '/analysis/tagWordChart',
    method: 'POST',
    data
  })
}
// 词云
export function WordCloud (data) {
  return request({
    url: '/analysis/wordCloud',
    method: 'POST',
    data
  })
}
