<template>
  <section class="new-list">
    <el-table
      v-if="data.length > 0"
      :data="data"
      :show-header="false"
      style="width: 100%">
      <el-table-column  :resizable="false">
        <template scope="{ row }">
          <div style="display: inline-block;vertical-align: top;margin-right: 4px;">
            <el-tag size="mini" v-if="row.isDanger === 0" effect="dark" style="background-color: #84BFEC;border-color:#84BFEC;height:19px;padding:0 3px;border-radius:2px;">正</el-tag>
            <el-tag size="mini" v-else-if="row.isDanger === 1"  effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;">负</el-tag>
            <el-tag size="mini" v-else-if="row.isDanger === 2" effect="dark" style="background-color: #EDD990;border-color:#EDD990;height:19px;padding:0 3px;border-radius:2px;">敏</el-tag>
          </div>
          <div class="card-content">
            <div class="title" v-html="row.introduction" @click="getNewsDetailsThroughsn(row.sn)"></div>
            <div class="footer">
              <div style="width: 30%;float: left;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              <span style="margin-right: 20px">
               发布者：{{ row.authorName }}
              </span>
              </div>
              <div style="width: 40%;float: left;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              <span style="">
               来源：{{ row.source }}
                </span>
              </div>
              <div style="width: 30%;float: left;text-align: right">
                <span style="float: right;">
                 {{ dateFormat(row) }}
                </span>
              </div>
            </div>
          </div>
          </template>
      </el-table-column>
      <!-- <el-table-column  :resizable="false"
        prop="introduction"
        label="简介">
        <template scope="{ row }">
          <span class="introduction" style="color: #3086FF;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;" @click="getNewsDetailsThroughsn(row.sn)"> {{ row.introduction }}</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"
        align="center"
        class="news-source"
        width="80"
        label="来源">
        <template scope="{ row }">
          <span class="news-source">{{ row.source }}</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false"
        prop="time"
        align="center"
        width="160"
        label="时间" :formatter="dateFormat">
      </el-table-column> -->
    </el-table>
    <div v-else align="center" style="margin-top: 50px;color: #898989;">暂无数据</div>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Array
    }
  },
  methods: {
    getNewsDetailsThroughsn (sn) {
      const { href } = this.$router.resolve({
        name: 'newsdetail',
        params: {
          sn: sn,
          type: 0
        }
      })
      console.log(href)
      window.open(href, '_blank')
      // this.$router.push(`monitor/list/${this.$route.params.id}/${this.$route.params.nodeId}`)
    },
    dateFormat (row, column) {
	    var time = row.time.replace(/-/g, '/').substr(0, row.time.indexOf('.'))
      var t = new Date(time)
      var year = t.getFullYear()
      var month = t.getMonth() + 1
      var day = t.getDate()
      var hour = t.getHours()
      var min = t.getMinutes()
      var sec = t.getSeconds()
      var newTime = year + '-' +
    　　  (month < 10 ? '0' + month : month) + '-' +
   　　   (day < 10 ? '0' + day : day) + ' ' +
    　　  (hour < 10 ? '0' + hour : hour) + ':' +
    　　  (min < 10 ? '0' + min : min) + ':' +
    　　  (sec < 10 ? '0' + sec : sec)
      return newTime
    }
  }
}
</script>

<style lang="scss">
.new-list {
  padding: 0 30px;
  .el-table__row {
    padding: 0 20px;
  }
  img{
    width: 16px;
    height: 16px;
  }
  .card-content{
    width: 94%;
    display: inline-block;
  }
  .title{
    font-size: 16px;
    color: #3d3d3d;
    margin-bottom: 12px;
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    white-space:nowrap; //溢出不换行
    cursor: pointer;
  }
  .footer{
    color: #828282;
  }
  .news-source {
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    white-space:nowrap; //溢出不换行
  }
  .introduction {
    color: #3086FF;
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    white-space:nowrap; //溢出不换行
    cursor: pointer;
  }
}
</style>
