<template>
  <section class="over-view-wrap" v-loading.fullscreen.lock="loading">
    <section class="latest-opinion-wrap">
      <div class="top-wrap" id="guideElement14">
        <div>
          <img src="../../assets/overview/icon-zuixin.png" />
          <span class="title">最新舆情</span>
        </div>
<!--          <el-dropdown trigger="click" @command="handleNews">-->
<!--            <span class="el-dropdown-link">-->
<!--              {{newsRange}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </span>-->
<!--            <el-dropdown-menu slot="dropdown">-->
<!--              <el-dropdown-item command="一天内">一天内</el-dropdown-item>-->
<!--              <el-dropdown-item command="一周内">一周内</el-dropdown-item>-->
<!--              <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
      </div>
      <div class="content" id="zmyq" @mouseover="stopZmScroll" @mouseleave="startZmScroll">
        <new-list v-loading="newNewsLoading" :data="news" />
      </div>
    </section>
    <section class="negative-public-opinion-wrap">
      <div class="top-wrap" id="guideElement15">
        <img src="../../assets/overview/icon-fumianyuqing.png" />
        <span class="title">负面舆情</span>
<!--          <el-dropdown trigger="click" @command="handleNegative">-->
<!--            <span class="el-dropdown-link">-->
<!--              {{negativeRange}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </span>-->
<!--            <el-dropdown-menu slot="dropdown">-->
<!--              <el-dropdown-item command="一天内">一天内</el-dropdown-item>-->
<!--              <el-dropdown-item command="一周内">一周内</el-dropdown-item>-->
<!--              <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
      </div>
      <div class="content" id="fmyq" @mouseover="stopFuScroll" @mouseleave="startFuScroll">
        <new-list v-loading="funewNewsLoading" :data="negative" />
      </div>
    </section>

    <section class="emotional-trend">
      <div class="top-wrap" id="guideElement16">
        <img src="../../assets/overview/icon-qinggan.png" />
        <span class="title">情感走势</span>
        <el-dropdown trigger="click" @command="initEmotionRange" style="float: right;color: #b2b2b2;">
          <span class="el-dropdown-link">
            {{emotionRange}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="一天内">一天内</el-dropdown-item>
            <el-dropdown-item command="一周内">一周内</el-dropdown-item>
            <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="noData" v-show="noEmotionData">暂无数据</div>
      <div id="trend"></div>
    </section>
    <section class="information-statistics-wrap">
      <div class="top-wrap" id="guideElement17">
        <img src="../../assets/overview/icon-fenbu.png" />
        <span class="title">舆情分布</span>
        <el-dropdown trigger="click" @command="infoSta" style="float: right;color: #b2b2b2;">
          <span class="el-dropdown-link">
            {{infoStatRange}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="一天内">一天内</el-dropdown-item>
            <el-dropdown-item command="一周内">一周内</el-dropdown-item>
            <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="noData" v-show="noInfoData">暂无数据</div>
      <div id="statistics"></div>
    </section>
    <section class="site-ranking-wrap">
      <div class="top-wrap" id="guideElement18">
        <img src="../../assets/overview/icon-zhandian.png" />
        <span class="title">站点排行</span>
        <el-dropdown trigger="click" @command="handleSite" style="float: right;color: #b2b2b2;">
          <span class="el-dropdown-link">
            {{siteRange}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="一天内">一天内</el-dropdown-item>
            <el-dropdown-item command="一周内">一周内</el-dropdown-item>
            <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="noData" v-show="noSiteData">暂无数据</div>
      <div id="ranking"></div>
    </section>
    <section class="hot-word-wrap">
      <div class="top-wrap" id="guideElement19">
        <img src="../../assets/overview/icon-hotword.png" />
        <span class="title">热词云图</span>
        <el-dropdown trigger="click" @command="wordClouds" style="float: right;color: #b2b2b2;">
          <span class="el-dropdown-link">
            {{hotWordRange}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="一天内">一天内</el-dropdown-item>
            <el-dropdown-item command="一周内">一周内</el-dropdown-item>
            <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="noData" v-show="noHotWord">暂无数据</div>
      <div id="hotWord"></div>
    </section>
    <section class="tag-word-wrap">
      <div class="top-wrap" id="guideElement20">
        <img src="../../assets/overview/icon-tag.png" />
        <span class="title">标签云图</span>
        <el-dropdown trigger="click" @command="MakeleSite" style="float: right;color: #b2b2b2;">
          <span class="el-dropdown-link">
            {{MakeleSiteRangeRange}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="一天内">一天内</el-dropdown-item>
            <el-dropdown-item command="一周内">一周内</el-dropdown-item>
            <el-dropdown-item command="一个月内">一个月内</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      <div class="noData" v-show="noTagWord">暂无数据</div>
      <div id="tagWord"></div>
    </section>
  </section>
</template>

<script>
import NewList from '@/components/NewList'
import { getLastestNews, siteStatistics, getInfoStatistics, getEmotion, tagWordCharts, WordCloud } from '@/api/overView'
import { getIsTrial } from '@/utils/auth'
export default {
  components: {
    NewList
  },
  methods: {
    async InfoEmoLine (type, date) {
      this.tu3.showLoading({
        text: '加载中...',
        color: '#87BAF9',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0,
        fuBox: {},
        zmBox: {}
      })
      const res = await getEmotion({ selectScope: type, dateNumber: date })
      if (res.data ===null || res.data.xAxis&&res.data.xAxis.length === 0 || res.data.series===null) {
        this.noEmotionData = true
      } else {
        this.lineChart(res)
        this.noEmotionData = false
      }
      this.tu3.hideLoading()
    },
    async InfoStatistics (type) {
      this.tu1.showLoading({
        text: '加载中...',
        color: '#87BAF9',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await getInfoStatistics({ type: type })
      this.pieChart(res)
      this.tu1.hideLoading()
      return res
    },
    async getNews (range) {
      try {
        const res = await getLastestNews({ newestSelectScope: range })
        this.news = res.data
        this.newNewsLoading = false;
      } catch (e) {
        console.log(e)
      }
    },
    async getNewsFu (range) {
      try {
        const res = await getLastestNews({ newestSelectScope: range, isDanger: 1 })
        this.negative = res.data
        this.funewNewsLoading = false;
      } catch (e) {
        console.log(e)
      }
    },
    pieChart (res) {
      // 基于准备好的dom，初始化echarts实例
      if (res.code === 200) {
        this.noInfoData = false
      } else {
        this.noInfoData = true
        this.tu1.clear()
      }
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
            trigger: 'item',
            formatter: function(data){ return `${data.seriesName} <br/>${data.name} : ${data.value} (`+data.percent.toFixed(2)+"%)";}
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        legend: {
          x: 'center',
          y: 'bottom',
          data: res.legend
        },
        color: ['#3372C7', '#F56C6C', '#ffdb8a'],
        series: [
          {
            name: '情感类型',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              emphasis: {
                show: true
              }
            },
            lableLine: {
              normal: {
                show: false
              },
              emphasis: {
                show: true
              }
            },
            data: res.data
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      this.tu1.setOption(option)
    },
    async barChart (dateType) {
      this.tu2.showLoading({
        text: '加载中...',
        color: '#87BAF9',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await siteStatistics({ dateType: dateType })
      if (res.data === null || res.data.series.length === 0) {
        this.noSiteData = true
      } else {
        this.noSiteData = false
        // this.siteData = res.data
        res.data.series.forEach((item, index) => {
          this.seriesData.push(item.value)
        })
      }
      let option = {
        // toolbox: {
        //     feature: {
        //       saveAsImage: {
        //           pixelRatio: 2
        //       }
        //     }
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: res.data?res.data.xAxis:[],
          axisLabel: {
            interval: 0,
            rotate: 45 // 代表逆时针旋转45度
          }
        },
        yAxis: {
          type: 'value'
        },
        color: ['#3372C7'],
        series: [{
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff'
          },
          data: this.seriesData,
          type: 'bar'
        }]
      }

      this.tu2.setOption(option)
      this.tu2.hideLoading()
    },
    async viewWordCloud (dateType) {
      this.tu4.showLoading({
        text: '加载中...',
        color: '#87BAF9',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      // 基于准备好的dom，初始化echarts实例
      const res = await WordCloud({ type: dateType })
      if (res.data.series.length === 0) {
        this.noHotWord = true
      } else {
        this.noHotWord = false
      }
      this.siteData = res.data
      // 绘制图表
      let option = {
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}'
        },
        series: [{
          name: '热词',
          type: 'wordCloud',
          size: ['80%', '80%'],
          textRotation: [0, 45, 90, -45],
          textPadding: 0,
          autoSize: {
            enable: true,
            minSize: 14
          },
          textStyle: {
            fontFamily: 'sans-serif',
            // Color can be a callback function or a color string
            color: function () {
              // Random color
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')'
            }
          },
          emphasis: {
            focus: 'self',
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: this.siteData.series
        }]
      }
      this.tu4.setOption(option)
      this.tu4.hideLoading()
    },
    lineChart (res) {
      var totalArr = []
      if(res.data && res.data.series){
        res.data.series[0].yBadData.forEach((item,index)=>{
          totalArr.push(item+res.data.series[0].yNotBadData[index]+res.data.series[0].yMGData[index])
        })
      }
      let option = {
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        legend: {
          data: ['负面', '正面', '敏感']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.data.xAxis
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        color: ['#F56C6C', '#3372C7', '#ffdb8a'],
        series: [
          {
            name: '总量',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              normal: {
                color: 'rgba(128, 128, 128, 0)' // 仍为透明
              }
            },
            data: totalArr
          }, {
            name: '负面',
            type: 'line',
            stack: '总量1',
            symbol: 'none',
            data: res.data.series[0].yBadData,
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(245, 108, 108, 1)'
                },
                {
                  offset: 1,
                  color: 'rgba(245, 108, 108, .5)'
                }
              ])
            }
          },
          {
            name: '正面',
            type: 'line',
            stack: '总量2',
            symbol: 'none',
            data: res.data.series[0].yNotBadData,
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(51, 114, 199, 1)'
                },
                {
                  offset: 1,
                  color: 'rgba(51, 114, 199, .5)'
                }
              ])
            }
          },
          {
            name: '敏感',
            type: 'line',
            stack: '总量3',
            symbol: 'none',
            data: res.data.series[0].yMGData,
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255, 219, 138, 1)'
                },
                {
                  offset: 1,
                  color: 'rgba(255, 219, 138, .5)'
                }
              ])
            }
          }
        ]
      }

      this.tu3.setOption(option)
    },
    // 信息统计
    infoSta (command) {
      this.infoStatRange = command
      if (command === '一天内') {
        this.InfoStatistics('today')
      } else if (command === '一周内') {
        this.InfoStatistics('week')
      } else {
        this.InfoStatistics('month')
      }
    },
    handleSite (command) {
      this.siteRange = command
      if (command === '一天内') {
        this.barChart('day')
        this.seriesData = []
      } else if (command === '一周内') {
        this.barChart('week')
        this.seriesData = []
      } else {
        this.barChart('month')
        this.seriesData = []
      }
    },
    wordClouds (command) {
      this.hotWordRange = command
      if (command === '一天内') {
        this.viewWordCloud('1')
        this.seriesData = []
      } else if (command === '一周内') {
        this.viewWordCloud('3')
        this.seriesData = []
      } else {
        this.viewWordCloud('4')
        this.seriesData = []
      }
    },
    initEmotionRange (command) {
      this.emotionRange = command
      if (command === '一天内') {
        this.InfoEmoLine('day', 1)
      } else if (command === '一周内') {
        this.InfoEmoLine('week', 7)
      } else {
        this.InfoEmoLine('month', 30)
      }
    },
    MakeleSite (command) {
      this.MakeleSiteRangeRange = command
      if (command === '一天内') {
        this.tagWordChart('1')
      } else if (command === '一周内') {
        this.tagWordChart('3')
      } else {
        this.tagWordChart('4')
      }
    },
    // 最新舆情
    handleNews (command) {
      this.newNewsLoading = true
      this.newsRange = command
      if (command === '一天内') {
        this.getNews('day')
      } else if (command === '一周内') {
        this.getNews('week')
      } else {
        this.getNews('month')
      }
    },
    // 负面舆情
    handleNegative (command) {
      this.funewNewsLoading = true
      this.negativeRange = command
      if (command === '一天内') {
        this.getNewsFu('day')
      } else if (command === '一周内') {
        this.getNewsFu('week')
      } else {
        this.getNewsFu('month')
      }
    },
    async tagWordChart (timestate) {
      this.tu5.showLoading({
        text: '加载中...',
        color: '#87BAF9',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      // 基于准备好的dom，初始化echarts实例
      const res = await tagWordCharts({ type: timestate })
      if (res.data.series.length === 0) {
        this.noTagWord = true
      } else {
        this.noTagWord = false
      }
      // 调用接口
      // 绘制图表
      let option = {
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}'
        },
        series: [{
          name: '标签',
          type: 'wordCloud',
          shape: 'diamond',
          size: ['100%', '100%'],
          textRotation: [0, 45, 90, -45],
          textPadding: 0,
          drawOutOfBound:true,
          sizeRange:[10, 25],
          rotationRange: [0, 0],
          autoSize: {
            enable: false,
            minSize: 14
          },
          textStyle: {
            fontFamily: 'sans-serif',
            // Color can be a callback function or a color string
            color: function () {
              // Random color
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')'
            }
          },
          emphasis: {
            focus: 'self',
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: res.data.series
        }]
      }
      console.log(res.data.series.length)
      // 为echarts对象加载数据
      this.tu5.setOption(option)
      this.tu5.hideLoading()
    },
    async startFuScroll () {
      let fuBox = document.getElementById('fmyq')
      var fuFlag = false
      var _this = this
      var fuAutoScroll = setInterval(() => {
        if (!fuFlag && fuBox.scrollTop + (100 * 5) >= fuBox.scrollHeight) {
          // _this.getNewsFu('day')
          fuFlag = true
          var backTop = setInterval(() => {
            if (fuBox.scrollTop > 0) {
              fuBox.scrollTop -= 50
            } else {
              clearInterval(backTop)
            }
          }, 5)
        } else {
          fuFlag = false
          var height = 0
          var index = setInterval(() => {
            if (height < 415) {
              fuBox.scrollTop += 5
              height += 5
            } else {
              clearInterval(index)
            }
          }, 5)
        }
      }, 5000)
      this.fuBox = fuAutoScroll
    },
    stopFuScroll () {
      clearInterval(this.fuBox)
    },
    async startZmScroll () {
      let zmBox = document.getElementById('zmyq')
      var zmFlag = false
      var _this = this
      var zmAutoScroll = setInterval(() => {
        if (!zmFlag && zmBox.scrollTop + (100 * 5) >= zmBox.scrollHeight) {
          // _this.getNews('day')
          zmFlag = true
          var backTop = setInterval(() => {
            if (zmBox.scrollTop > 0) {
              zmBox.scrollTop -= 50
            } else {
              clearInterval(backTop)
            }
          }, 5)
        } else {
          zmFlag = false
          var height = 0
          var index = setInterval(() => {
            if (height < 415) {
              zmBox.scrollTop += 5
              height += 5
            } else {
              clearInterval(index)
            }
          }, 5)
        }
      }, 5000)
      this.zmBox = zmAutoScroll
    },
    stopZmScroll () {
      clearInterval(this.zmBox)
    }
    /* async scroll () {
      const elOffsetTop = document.getElementsByClassName('information-statistics-wrap')[0].offsetTop
      const hotWordTop = document.getElementsByClassName('hot-word-wrap')[0].offsetTop
      const docScrollTop = document.documentElement.scrollTop
      if (elOffsetTop >= docScrollTop && elOffsetTop < (docScrollTop + window.innerHeight) ) {
        if(this.flag === false) return
        this.flag = false
        this.infoSta('一个月内')
        this.barChart('month')
      }
      if (hotWordTop >= docScrollTop && hotWordTop < (docScrollTop + window.innerHeight) ) {
        if(this.hotFlag === false) return
        this.hotFlag = false
        this.viewWordCloud('3')
        this.tagWordChart('4')
      }
    } */
  },
  mounted () {
    if (getIsTrial() && !localStorage.getItem(localStorage.getItem('ct_account') + 'doneFirstGuide')) {
      this.$router.push('/monitor/list')
    }
    this.loading = true
    this.tu1 = this.$echarts.init(document.getElementById('statistics'))
    this.tu2 = this.$echarts.init(document.getElementById('ranking'))
    this.tu3 = this.$echarts.init(document.getElementById('trend'))
    this.tu4 = this.$echarts.init(document.getElementById('hotWord'))
    this.tu5 = this.$echarts.init(document.getElementById('tagWord'))
    this.tagWordChart('4')
    this.viewWordCloud('4')
    this.getNews('day')
    this.getNewsFu('day')
    this.InfoEmoLine('month', 30)
    this.infoSta('一个月内')
    this.handleSite('一个月内')
    this.loading = false
    this.startFuScroll()
    this.startZmScroll()
    /* this.$nextTick(() => {
      this.scroll()
      window.addEventListener('scroll', this.scroll)
    }) */
  },
  /* destroyed () {
    window.removeEventListener('scroll', this.scroll, false)
  }, */
  data () {
    return ({
      tu1: '',
      tu2: '',
      tu3: '',
      tu4: '',
      tu5: '',
      flag: true,
      hotFlag: true,
      newNewsLoading: true,
      funewNewsLoading: true,
      MakeleSiteRangeRange: '一个月内',
      news: [],
      negative: [],
      siteData: [],
      seriesData: [],
      hotWordRange: '一个月内',
      siteRange: '一个月内',
      newsRange: '一天内',
      negativeRange: '一天内',
      infoStatRange: '一个月内',
      emotionRange: '一个月内',
      noInfoData: false,
      noSiteData: false,
      noEmotionData: false,
      noTagWord: false,
      noHotWord: false,
      loading: false
    })
  }
}
</script>

<style lang="scss" scoped>
.over-view-wrap {
  // padding: 30px;
  // padding-top: 20px;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .noData{
    width: 100%;
    text-align: center;
    position: absolute;
    color: #898989;
    top: 180px;
  }
  .information-statistics-wrap {
    // width: 718px;
    width: 49%;
    height: 400px;
    margin-right: 2%;
    background: #fff;
    box-shadow:0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 5px;
    position: relative;
    /* .title {
      font-size: 20px;
      color: #000;
      margin: 0;
      margin-left: 18px;
      margin-top: 20px;
      display: inline-block;
    } */
    #statistics {
      width: 95%;
      height: calc(100% - 60px);
    }
  }
  .site-ranking-wrap {
    // width: 718px;
    width: 49%;
    height: 400px;
    background: #fff;
    box-shadow:0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 5px;
    position: relative;
    canvas {
      width: 100% !important;
    }
   /* .title {
      font-size: 20px;
      color: #000;
      margin: 0;
      margin-left: 18px;
      margin-top: 20px;
      display: inline-block;
    } */
    #ranking {
      width: 100%;
      height: calc(100% - 60px);
    }
  }
  .emotional-trend {
    width: 100%;
    height:455px;
    background:#fff;
    box-shadow:0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    canvas {
      width: 100% !important;
    }
    /* .title {
      font-size: 20px;
      color: #000;
      margin: 0;
      margin-left: 18px;
      margin-top: 20px;
      display: inline-block;
    } */
    #trend {
      width: 100%;
      height: calc(100% - 60px);
    }
  }
  .el-dropdown {
    cursor: pointer;
  }
  .top-wrap {
    width: 100%;
    font-size: 16px;
    color: #698ae5;
    // display: flex;
    // justify-content: space-between;
    padding: 20px 22px 20px;
    border-bottom: 1px solid #eaeaea;
    img{
      width: 16px;
      height: 16px;
      vertical-align: text-top;
      margin-right: 4px;
    }
  }
  .latest-opinion-wrap {
    // width: 718px;
    width: 49%;
    margin-right: 2%;
    min-height: 200px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 5px;
    .content{
      max-height: 500px;
      overflow-y: scroll;
    }
    .content::-webkit-scrollbar {
     width: 0;
    }
  }
  .negative-public-opinion-wrap {
    // width: 718px;
    width: 49%;
    min-height: 200px;
    background: #fff;
    box-shadow:0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 5px;
    .content{
      max-height: 500px;
      overflow-y: scroll;
    }
    .content::-webkit-scrollbar {
     width: 0;
    }
    /* .top-wrap {
      width: 100%;
      font-size: 20px;
      font-weight: bolder;
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 20px;
    } */
  }
  .hot-word-wrap, .tag-word-wrap {
    // width: 718px;
    width: 49%;
    height: 400px;
    background: #fff;
    box-shadow:0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 5px;
    margin-top: 20px;
    position: relative;
    /* .title {
      font-size: 20px;
      color: #000;
      margin: 0;
      margin-left: 18px;
      margin-top: 20px;
      display: inline-block;
    } */
    #hotWord, #tagWord {
      width: 95%;
      height: calc(100% - 60px);
    }
  }
  .hot-word-wrap{
    margin-right: 2%;
  }
}
</style>
